import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState, } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCustomNavigate } from '@src/hooks/useCustomNavigate';
import { ErrorPageType } from '@src/pages/ErrorPage/types';
import { useNotifications } from '../NotificationsContext';
import { FIRST_TIMEOUT_TO_REQUEST, TIMEOUT_TO_REQUEST_OFFLINE, TIMEOUT_TO_REQUEST_ONLINE, } from './constants';
import { useAppData } from './hooks/useAppData';
import { useUrlCheck } from './hooks/useUrlCheck';
import { Flow, } from './types/context';
const Context = createContext(null);
let timeoutToRequest = FIRST_TIMEOUT_TO_REQUEST;
export function ApiContext({ children }) {
    const [dataResStatus, setDataResStatus] = useState('idle');
    const [deviceInfo, setDeviceInfo] = useState();
    const [deviceResStatus, setDeviceResStatus] = useState('idle');
    const initialData = useUrlCheck();
    const navigate = useNavigate();
    const { navigateWithFlow, navigateToErrorPage } = useCustomNavigate();
    const { setNotification } = useNotifications();
    const { appData, getData, getDeviceInfo, updatePlacementCode, getDeviceStatus, } = useAppData();
    const value = useMemo(() => ({
        appData,
        dataResStatus,
        deviceInfo,
        deviceResStatus,
        updatePlacementCode,
    }), [appData, dataResStatus, deviceResStatus, deviceInfo, updatePlacementCode]);
    const getDeviceStatusHandler = useCallback(async (deviceUid) => {
        const isDeviseOnline = await getDeviceStatus(deviceUid)
            .then((status) => {
            setDeviceResStatus('received');
            setDeviceInfo((prevState) => ({
                ...prevState,
                is_online: status,
            }));
            return status;
        })
            .catch(() => {
            setDeviceResStatus('rejected');
            setNotification({
                status: 'error',
                value: 'Device status error',
            });
        });
        timeoutToRequest = isDeviseOnline
            ? TIMEOUT_TO_REQUEST_ONLINE
            : TIMEOUT_TO_REQUEST_OFFLINE;
        const timeout = setTimeout(() => {
            document.removeEventListener('visibilitychange', () => {
                const isTabVisible = document.visibilityState === 'visible';
                if (!isTabVisible)
                    clearTimeout(timeout);
            });
            getDeviceStatusHandler(deviceUid);
        }, timeoutToRequest);
        document.addEventListener('visibilitychange', () => {
            const isTabVisible = document.visibilityState === 'visible';
            if (!isTabVisible)
                clearTimeout(timeout);
        });
    }, [getDeviceStatus, setNotification]);
    const getDeviceInfoHandler = useCallback((deviceUid) => {
        getDeviceInfo(deviceUid)
            .then((data) => {
            setDeviceResStatus('received');
            setDeviceInfo(data);
        })
            .catch(() => {
            setDeviceResStatus('rejected');
            setNotification({
                status: 'error',
                value: 'Device status error',
            });
        });
        setTimeout(() => {
            getDeviceStatusHandler(deviceUid);
        }, timeoutToRequest);
    }, [getDeviceInfo, setNotification, getDeviceStatusHandler]);
    useLayoutEffect(() => {
        const { flow, userUid, deviceUid, isAppPage } = initialData;
        if (flow === Flow.none)
            return;
        if (flow === Flow.error) {
            navigateToErrorPage(ErrorPageType.loadingError);
            return;
        }
        const appPage = flow === Flow.currier ? '/currier' : 'owner';
        if (dataResStatus === 'idle') {
            setDataResStatus('pending');
            getData(deviceUid, userUid, flow)
                .then((data) => {
                if (!data.orders.length) {
                    setDataResStatus('rejected');
                    navigateToErrorPage(ErrorPageType.noOrders);
                    return;
                }
                setDataResStatus('received');
                if (isAppPage) {
                    return;
                }
                if (flow === Flow.currier) {
                    navigate(appPage);
                    return;
                }
                navigateWithFlow(appPage);
            })
                .catch(() => {
                setDataResStatus('rejected');
                navigateToErrorPage(ErrorPageType.loadingError);
            });
        }
        // We must call this once, when we already received settings from url
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialData, dataResStatus]);
    useEffect(() => {
        if (dataResStatus !== 'received' || !appData || deviceResStatus !== 'idle')
            return;
        const { deviceUid } = appData;
        setDeviceResStatus('pending');
        getDeviceInfoHandler(deviceUid);
        function fetchDeviceStatus() {
            const isTabVisible = document.visibilityState === 'visible';
            if (isTabVisible && !deviceInfo?.is_online)
                getDeviceStatusHandler(deviceUid);
        }
        document.addEventListener('visibilitychange', () => fetchDeviceStatus());
        return () => {
            document.removeEventListener('visibilitychange', () => fetchDeviceStatus());
        };
    }, [
        appData,
        dataResStatus,
        deviceResStatus,
        initialData,
        deviceInfo,
        getDeviceInfo,
        setNotification,
        getDeviceInfoHandler,
        getDeviceStatusHandler,
    ]);
    return _jsx(Context.Provider, { value: value, children: children });
}
export function useApiContext() {
    const value = useContext(Context);
    if (!value) {
        throw new Error('Please, use hook inside ApiContext');
    }
    return value;
}
