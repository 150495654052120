import styled, { css } from 'styled-components';
import ChevronSVG from '@src/assets/chevron.svg';
import MenuSVG from '@src/assets/menu.svg';
import OwnerSVG from '@src/assets/owner.svg';
import SupportSVG from '@src/assets/support.svg';
const icon = css `
  flex: 0 0 24px;
`;
export const ChevronIcon = styled(ChevronSVG) `
  ${icon}
`;
export const SupportIcon = styled(SupportSVG) `
  ${icon}
`;
export const MenuIcon = styled(MenuSVG) `
  ${icon}
`;
export const OwnerIcon = styled(OwnerSVG) `
  ${icon}
`;
export const HeaderContainer = styled.header `
  height: 40px;
  width: 100%;
  position: relative;
  padding-left: ${({ theme }) => theme.spacing(3)};
  padding-right: ${({ theme }) => theme.spacing(3)};

  display: flex;
  align-items: center;
`;
export const Button = styled.button `
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing()};
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.palette.text.primary};

  font-size: ${({ theme }) => theme.typography.fontSize.l};
  font-weight: ${({ theme }) => theme.typography.fontWeight[400]};
  letter-spacing: ${({ theme }) => theme.typography.letterSpacing.xxs};
  white-space: nowrap;

  &:last-child {
    margin-left: auto;
  }
`;
