import { COLORS } from './colors';
export const themeTypography = {
    typography: {
        fontFamily: {
            primary: 'DM Sans, sans-serif',
            secondary: 'Inter, sans-serif',
            special: 'Sohne Breit, sans-serif',
        },
        fontWeight: {
            200: '200',
            500: '500',
            400: '400',
            600: '600',
            700: '700',
        },
        fontSize: {
            xxxl: '1.2em',
            xxl: '1.1em',
            xl: '1em',
            e: '0.9em',
            l: '0.8em',
            m: '0.75em',
            s: '0.7em',
            xs: '0.6em',
        },
        letterSpacing: {
            xxs: '0.032px',
        },
    },
};
export const commonThemeData = {
    shape: {
        borderRadius: {
            xl: '200px',
            l: '10px',
            m: '8px',
            s: '4px',
            xs: '1px',
        },
    },
    transitionDuration: {
        normal: '0.3s',
    },
    spacing: (value = 1) => `${value * 8}px`,
};
export const defaultPalette = {
    palette: {
        error: COLORS.red,
        online: COLORS.green[40],
        background: {
            primary: COLORS.grey[15],
            secondary: COLORS.grey[10],
            hightLight: COLORS.grey[5],
            popup: COLORS.grey[70],
            black: COLORS.grey[100],
        },
        border: {
            primary: COLORS.grey[15],
            shadow: COLORS.grey[70],
            popup: COLORS.grey[40],
            code: COLORS.grey[10],
            light: COLORS.grey[0],
            black: COLORS.grey[100],
        },
        text: {
            primary: COLORS.grey[100],
            secondary: COLORS.grey[20],
            hightLight: COLORS.grey[0],
            special: COLORS.grey[40],
            popup: COLORS.grey[5],
        },
    },
};
