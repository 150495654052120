import styled, { css, keyframes } from 'styled-components';
import RefreshSVG from '@src/assets/refresh.svg';
import ShareSVG from '@src/assets/share.svg';
const spin = keyframes `
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;
const buttonCommon = css `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(1.25)};

  width: 160px;
  height: 56px;
  border-radius: ${({ theme }) => theme.shape.borderRadius.xl};
  border: none;
  outline: none;

  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeight[500]};
  font-size: ${({ theme }) => theme.typography.fontSize.xxl};

  padding-left: ${({ theme }) => theme.spacing(1)};
  padding-right: ${({ theme }) => theme.spacing(1)};
`;
export const RefreshIcon = styled(RefreshSVG) `
  width: 24px;
  height: 24px;
`;
function applyRefreshIconStyles({ isUpdating }) {
    return (isUpdating &&
        css `
      pointer-events: none;
      ${RefreshIcon} {
        animation: ${spin} 1s linear infinite;
      }
    `);
}
export const ShareIcon = styled(ShareSVG) `
  width: 24px;
  height: 24px;
`;
export const AccessCodeNumber = styled.p `
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(1.75)};
`;
export const Number = styled.span `
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 80px;

  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${({ theme }) => theme.typography.fontSize.xxxl};

  border-radius: ${({ theme }) => theme.shape.borderRadius.xs};
  border: 1px solid ${({ theme }) => theme.palette.border.code};
`;
export const ButtonsWrapper = styled.div `
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(1.5)};
`;
export const ShareButton = styled.button `
  ${buttonCommon};

  background-color: ${({ theme }) => theme.palette.background.black};

  color: ${({ theme }) => theme.palette.text.hightLight};
`;
export const RefreshButton = styled.button `
  ${buttonCommon};

  background-color: #fbff2f;

  color: ${({ theme }) => theme.palette.text.primary};
  ${applyRefreshIconStyles};
`;
