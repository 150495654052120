import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { StackName } from '@src/contexts/ApiContext/types/configurationView';
import { useApiContext } from '../ApiContext/ApiContext';
const Context = createContext(null);
export default function DeviceContext({ children, }) {
    const [isTwoCells, setIsTwoCells] = useState(false);
    const [isTwoCellsWithLeg, setIsTwoCellsWithLeg] = useState(false);
    const [selectedCell, setSelectedCell] = useState();
    const { appData } = useApiContext();
    const value = useMemo(() => ({
        isTwoCells,
        isTwoCellsWithLeg,
        selectedCell,
        setSelectedCell,
        setIsTwoCells,
        setIsTwoCellsWithLeg,
    }), [isTwoCells, isTwoCellsWithLeg, selectedCell]);
    useEffect(() => {
        const stacksName = appData?.configurationView.stacks[0].name;
        if (stacksName === StackName.twoCells) {
            setIsTwoCells(true);
        }
        if (stacksName === StackName.twoCellsWithLeg) {
            setIsTwoCellsWithLeg(true);
        }
    }, [appData]);
    return _jsx(Context.Provider, { value: value, children: children });
}
export function useDeviceContext() {
    const contextData = useContext(Context);
    if (!contextData)
        throw new Error('Please use hook useDevice inside DeviceContext provider');
    return contextData;
}
