import { createGlobalStyle } from 'styled-components';
import SohneBreitDreiviertelfettTTF from '@src/assets/fonts/SohneBreit-Dreiviertelfett.ttf';
import SohneBreitDreiviertelfettWOFF from '@src/assets/fonts/SohneBreit-Dreiviertelfett.woff';
import SohneBreitDreiviertelfettWOFF2 from '@src/assets/fonts/SohneBreit-Dreiviertelfett.woff2';
export const GlobalStyle = createGlobalStyle `
  @font-face {
    font-family: 'Sohne Breit';
    src: local('Sohne Breit'), local('SohneBreit'),
    url(${SohneBreitDreiviertelfettWOFF2}) format('woff2'),
    url(${SohneBreitDreiviertelfettWOFF}) format('woff'),
    url(${SohneBreitDreiviertelfettTTF}) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  body {
    min-height: 100dvh;

    font-size: 20px;
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};

    &, * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    }
  }
`;
