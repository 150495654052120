import { useLayoutEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { REGEXP_UID } from '../constants';
import { Flow, SearchParams } from '../types/context';
export function useUrlCheck() {
    const [initialData, setInitialData] = useState({
        flow: Flow.none,
        userUid: '',
        deviceUid: '',
        isAppPage: false,
    });
    const [searchParams] = useSearchParams();
    const { pathname } = useLocation();
    useLayoutEffect(() => {
        const pathData = pathname.split('/');
        const isOwnerPath = pathData[1]?.match(REGEXP_UID);
        const isDevicePath = pathData[2]?.match(REGEXP_UID);
        const ownerQuery = searchParams.get(SearchParams.owner)?.match(REGEXP_UID);
        const deviceQuery = searchParams
            .get(SearchParams.deviceUid)
            ?.match(REGEXP_UID);
        const isCurrierPage = pathname.includes('currier');
        const isOwnerPage = pathname.includes('owner');
        if ((isOwnerPath && isDevicePath) || isCurrierPage) {
            setInitialData({
                flow: Flow.currier,
                userUid: isOwnerPath?.[0] || '',
                deviceUid: isDevicePath?.[0] || '',
                isAppPage: isCurrierPage,
            });
            return;
        }
        if (ownerQuery && deviceQuery) {
            setInitialData({
                flow: Flow.owner,
                userUid: ownerQuery?.[0] || '',
                deviceUid: deviceQuery?.[0] || '',
                isAppPage: isOwnerPage,
            });
            return;
        }
        setInitialData((state) => ({
            ...state,
            flow: Flow.error,
        }));
        // We must check this once, when app is starting
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return initialData;
}
