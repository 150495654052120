import { useCallback, useState } from 'react';
import { request } from '@src/api';
import { Flow } from '../types/context';
export function useAppData() {
    const [appData, setAppData] = useState(null);
    const updatePlacementCode = useCallback((orderIndex, placementCode) => {
        const newAppData = JSON.parse(JSON.stringify(appData));
        newAppData.data.orders[orderIndex].code_placement = placementCode;
        sessionStorage.setItem(Flow.owner, JSON.stringify(newAppData));
        setAppData(newAppData);
    }, [appData]);
    function setData(data) {
        sessionStorage.setItem(data.flow, JSON.stringify(data));
        setAppData(data);
    }
    async function getData(deviceUid, userUid, flow) {
        const savedData = sessionStorage.getItem(flow);
        if (savedData) {
            const parsedData = JSON.parse(savedData);
            setAppData(parsedData);
            return parsedData.data;
        }
        const { data } = await request({
            url: `/info/owner/?user_uid=${userUid}&device_uid=${deviceUid}`,
        });
        const { data: configurationView } = await request({
            url: `device/${deviceUid}/configuration/view/`,
        });
        setData({
            data,
            deviceUid,
            userUid,
            flow,
            configurationView,
        });
        return data;
    }
    const getDeviceInfo = useCallback(async (deviceUid) => {
        const { data } = await request({
            url: `public/device/${deviceUid}/`,
            method: 'GET',
        });
        return data;
    }, []);
    const getDeviceStatus = useCallback(async (deviceUid) => {
        const { data } = await request({
            url: `public/device/status/${deviceUid}/`,
            method: 'GET',
        });
        return !!data?.online;
    }, []);
    return {
        appData,
        getData,
        getDeviceInfo,
        getDeviceStatus,
        updatePlacementCode,
    };
}
