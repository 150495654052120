import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import Button from '@src/components/Button';
import Device from '@src/components/Device';
import { useDeviceContext } from '@src/contexts/DeviceContext';
import { useOwnerFlowContext } from '@src/contexts/OwnerFlowContext';
import { useOpenDevice } from '@src/hooks/useOpenDevice';
import { MainTitle } from '@src/theme/commonElements';
import AccessCode from './components/AccessCode';
import useButtonForTwoCells from './hooks/useButtonForTwoCells';
import { ButtonsWrapper, TopWrapper } from './styled';
export default function Owner() {
    const { isLoading, openDeviceHandler } = useOpenDevice();
    const { ownerFlow, isShowAccessCode } = useOwnerFlowContext();
    const { isTwoCells, isTwoCellsWithLeg, selectedCell, setSelectedCell } = useDeviceContext();
    const { isSelectedCodeForCourier, openCellBtn, codeForCourierBtn } = useButtonForTwoCells();
    const isActiveButtons = Number.isInteger(selectedCell);
    const isDeviceWithTwoCells = isTwoCells || isTwoCellsWithLeg;
    function getMainTitle() {
        const title = isSelectedCodeForCourier
            ? 'Select a cell for ordering by clicking on it'
            : 'Select the cell you want to open';
        return ownerFlow ? title : 'OMNIHOME INFO';
    }
    useEffect(() => {
        setSelectedCell(undefined);
    }, [setSelectedCell]);
    return isDeviceWithTwoCells ? (_jsxs(_Fragment, { children: [_jsx(TopWrapper, { children: isShowAccessCode ? (_jsx(AccessCode, {})) : (_jsx(MainTitle, { children: getMainTitle() })) }), _jsx(Device, { isSmallDevice: isShowAccessCode }), _jsxs(ButtonsWrapper, { "$Flow": ownerFlow, "$isActive": isActiveButtons, children: [_jsx(Button, { isLoading: isLoading, onClick: openCellBtn.action, children: openCellBtn.text }), _jsx(Button, { isLoading: isLoading, onClick: codeForCourierBtn.action, children: codeForCourierBtn.text })] })] })) : (_jsxs(_Fragment, { children: [_jsx(TopWrapper, { children: _jsx(AccessCode, {}) }), _jsx(Device, {}), _jsx(Button, { isLoading: isLoading, onClick: openDeviceHandler, children: "Open OmniHome" })] }));
}
