import styled from 'styled-components';
export const TopWrapper = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(1.5)};
  flex: 1 1 auto;
`;
export const TopWrapperHight = styled.div `
  margin-top: ${({ theme }) => theme.spacing(5.5)};
`;
export const MainTitle = styled.h2 `
  text-align: center;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${({ theme }) => theme.typography.fontSize.xl};
  font-weight: ${({ theme }) => theme.typography.fontWeight[700]};
  letter-spacing: ${({ theme }) => theme.spacing(0.05)};
  text-transform: uppercase;
`;
export const SecondaryTitle = styled.h3 `
  text-align: center;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${({ theme }) => theme.typography.fontSize.xl};
  font-weight: ${({ theme }) => theme.typography.fontWeight[400]};
  letter-spacing: ${({ theme }) => theme.spacing(0.05)};
`;
export const Emoji = styled.p `
  text-align: center;
  margin: ${({ theme }) => theme.spacing(4)} 0;
  font-size: 45px;
`;
