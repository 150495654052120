import { useCallback } from 'react';
import { CODE_LENGTH, FIRST_INPUT_ID, LAST_INPUT_ID, MIN_LENGTH, } from '../constants';
export function useInputActions({ setValue, setIsInvalidCode, }) {
    const disableContextMenu = useCallback((event) => {
        event.preventDefault();
    }, []);
    function focusInputById(id) {
        const input = document.getElementById(id);
        input.focus();
    }
    function checkPrevInput(id) {
        const input = document.getElementById(id);
        if (!input.value) {
            focusInputById(id);
        }
    }
    const onFocusHandler = ({ target }) => {
        const targetId = target.id;
        if (target.value) {
            target.addEventListener('contextmenu', disableContextMenu);
            target.setSelectionRange(0, 1);
        }
        else {
            target.removeEventListener('contextmenu', disableContextMenu);
            if (targetId !== FIRST_INPUT_ID) {
                checkPrevInput(`${+targetId - 1}`);
            }
        }
    };
    function onChangeHandler({ target }) {
        const typedValue = target.value;
        const inputId = target.id;
        const { length: valueLength } = typedValue;
        const isValidInputData = !valueLength ||
            (!Number.isNaN(+typedValue) &&
                (valueLength === CODE_LENGTH || valueLength === MIN_LENGTH));
        if (!isValidInputData)
            return;
        if (!valueLength && inputId !== FIRST_INPUT_ID) {
            checkPrevInput(`${+inputId - 1}`);
        }
        if (valueLength === 6) {
            const splittedValue = typedValue.split('');
            setValue(splittedValue);
            return;
        }
        setIsInvalidCode(false);
        setValue((state) => {
            const newState = [...state];
            newState[+inputId] = typedValue;
            return newState;
        });
        if (!target.value) {
            target.removeEventListener('contextmenu', disableContextMenu);
        }
        if (typedValue && inputId !== LAST_INPUT_ID) {
            focusInputById(`${+inputId + 1}`);
        }
    }
    function onkeydownHandler(event) {
        const target = event.target;
        const id = +target.id;
        const typedValue = target.value;
        if (event.key === 'Backspace' && id && !typedValue) {
            focusInputById(`${id - 1}`);
        }
    }
    return {
        onFocusHandler,
        onChangeHandler,
        onkeydownHandler,
    };
}
