import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import ImageScanQR from '@src/assets/images/error_page.jpg';
import { Emoji, MainTitle } from '@src/theme/commonElements';
import { ErrorPageType } from './types';
import { ErrorInfoContainer, Image } from './styled';
export default function ErrorPage() {
    const location = useLocation();
    const state = location.state;
    const { title, emoji } = state === ErrorPageType.noOrders
        ? {
            title: 'There are no orders for this user yet...',
            emoji: '🙄',
        }
        : {
            title: 'Scan QR on the device to continue the process',
            emoji: _jsx(Image, { src: ImageScanQR, alt: "" }),
        };
    return (_jsxs(ErrorInfoContainer, { children: [_jsx(MainTitle, { children: title }), _jsx(Emoji, { role: "presentation", "aria-label": "Sad face", children: emoji })] }));
}
