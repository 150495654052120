import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation, useNavigate } from 'react-router-dom';
import { useApiContext } from '@src/contexts/ApiContext/ApiContext';
import { Flow } from '@src/contexts/ApiContext/types/context';
import { useDeviceContext } from '@src/contexts/DeviceContext';
import { useOwnerFlowContext } from '@src/contexts/OwnerFlowContext';
import { useHeaderSetting } from './hooks/useHeaderSetting';
import { TargetType } from './types';
import { Button, ChevronIcon, HeaderContainer, MenuIcon, OwnerIcon, SupportIcon, } from './styled';
const { ACCOUNT_URL } = process.env;
export default function Header() {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { appData } = useApiContext();
    const { ownerFlow, isShowAccessCode, setOwnerFlow, setIsShowAccessCode } = useOwnerFlowContext();
    const { setSelectedCell } = useDeviceContext();
    // TODO: Remove this feature in future
    const isHideChevron = useHeaderSetting();
    const isDinePage = pathname.includes('close-device');
    const ownerAccountUrl = `${ACCOUNT_URL}/?device_uid=${appData?.deviceUid}`;
    const ownerFormURL = `${ownerAccountUrl}&owner_phone=${appData?.data.company_franchisee.phone}&redirect_to=owner`;
    const supportUrl = 'https://omnicampus.atlassian.net/servicedesk/customer/portal/9';
    const isShowOwnerFlowBackBtn = ownerFlow && isHideChevron;
    const isShowIamOwnerBtn = isHideChevron && appData?.flow === Flow.currier;
    const { buttonText, url, icon, targetValue } = appData?.flow === Flow.owner
        ? {
            buttonText: 'Menu',
            url: ownerAccountUrl,
            icon: _jsx(MenuIcon, {}),
            targetValue: TargetType.Self,
        }
        : {
            buttonText: 'Support',
            url: supportUrl,
            icon: _jsx(SupportIcon, {}),
            targetValue: TargetType.Blank,
        };
    function redirectHandler({ redirectTo, target }) {
        setTimeout(() => {
            window.open(redirectTo, target)?.focus();
        });
    }
    function ownerFlowBackHandler() {
        if (isShowAccessCode) {
            setIsShowAccessCode(false);
        }
        else {
            setOwnerFlow(undefined);
            setSelectedCell(undefined);
        }
    }
    return (_jsxs(HeaderContainer, { children: [isShowIamOwnerBtn && (_jsxs(Button, { onClick: () => redirectHandler({
                    redirectTo: ownerFormURL,
                    target: TargetType.Self,
                }), children: [_jsx(OwnerIcon, {}), " I'm Owner"] })), !isDinePage && !isHideChevron && (_jsx(ChevronIcon, { onClick: () => navigate(-1) })), !isDinePage && isShowOwnerFlowBackBtn && (_jsx(ChevronIcon, { onClick: ownerFlowBackHandler })), _jsxs(Button, { onClick: () => redirectHandler({ redirectTo: url, target: targetValue }), children: [buttonText, " ", icon] })] }));
}
