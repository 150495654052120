import { jsx as _jsx } from "react/jsx-runtime";
import Loader from '../Loader';
import { ButtonCommon, StyledA, StyledLink } from './styled';
export default function Button({ to, href, children, isActive = true, isLoading = false, onClick, }) {
    const isActiveBtn = !isLoading && isActive;
    function getButton() {
        const button = (_jsx(ButtonCommon, { onClick: onClick, isActive: isActiveBtn, children: isLoading ? _jsx(Loader, {}) : children }));
        if (to) {
            return _jsx(StyledLink, { to: to, children: button });
        }
        if (href) {
            return _jsx(StyledA, { href: href, children: button });
        }
        return button;
    }
    return getButton();
}
