import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Battery from '@src/components/Device/components/Battery';
import Signal from '@src/components/Device/components/Signal';
import Status from '@src/components/Device/components/Status';
import useDeviceBehavior from '../../hooks/useDeviceBehavior';
import { ClosedCover, CoverFastening, DeviceBody, DeviceBottom, DeviceContainer, DeviceLeftLeg, DeviceLoader, DeviceRightLeg, OpenedCover, TextInfo, } from './styled';
export default function OneCell({ open, hideTextStatus, isInvalidCode, }) {
    const { isShowedTextStatus, isShowPercents, status, toggleBatteryInfo, getStatusText, } = useDeviceBehavior({ hideTextStatus });
    return (_jsxs(DeviceContainer, { role: "presentation", "aria-label": "OmniHome device", children: [open ? (_jsxs(OpenedCover, { children: [_jsx(CoverFastening, {}), _jsx(CoverFastening, {})] })) : (_jsxs(_Fragment, { children: [isShowedTextStatus && (_jsx(Status, { status: status, isInvalidCode: isInvalidCode })), _jsx(ClosedCover, {})] })), _jsxs(DeviceBody, { status: status, onClick: toggleBatteryInfo, children: [_jsx(Signal, {}), _jsx(Battery, { isShowPercents: isShowPercents }), _jsx(DeviceLoader, { children: _jsx(TextInfo, { children: getStatusText() }) })] }), _jsxs(DeviceBottom, { children: [_jsx(DeviceRightLeg, {}), _jsx(DeviceLeftLeg, {})] })] }));
}
