import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Button from '@src/components/Button';
import Device from '@src/components/Device';
import { useApiContext } from '@src/contexts/ApiContext/ApiContext';
import { Flow } from '@src/contexts/ApiContext/types/context';
import { useDeviceContext } from '@src/contexts/DeviceContext';
import { useOwnerFlowContext } from '@src/contexts/OwnerFlowContext';
import { useCustomNavigate } from '@src/hooks/useCustomNavigate';
import { TopWrapper } from '@src/theme/commonElements';
import { Title } from './styled';
export default function CloseDevice() {
    const { navigateWithFlow } = useCustomNavigate();
    const { setOwnerFlow } = useOwnerFlowContext();
    const { setSelectedCell } = useDeviceContext();
    const { appData } = useApiContext();
    const { flow } = appData;
    function buttonHandler() {
        setSelectedCell(undefined);
        if (flow === Flow.owner) {
            setOwnerFlow(undefined);
            return navigateWithFlow('/');
        }
        navigateWithFlow('/done');
    }
    return (_jsxs(_Fragment, { children: [_jsx(TopWrapper, { children: _jsxs(Title, { children: ["Please do not forget ", _jsx("br", {}), "to close the box"] }) }), _jsx(Device, { open: true, hideTextStatus: true }), _jsx(Button, { onClick: buttonHandler, children: "Done" })] }));
}
