import styled, { css } from 'styled-components';
import Link from '../Link';
function applyButtonStyles({ isActive, }) {
    return isActive
        ? css `
        pointer-events: all;
      `
        : css `
        pointer-events: none;
      `;
}
export const ButtonCommon = styled.button `
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  width: 330px;
  height: 56px;

  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => theme.typography.fontSize.xxl};
  font-weight: ${({ theme }) => theme.typography.fontWeight[500]};
  color: ${({ theme }) => theme.palette.text.hightLight};

  background-color: ${({ theme }) => theme.palette.background.black};
  border: none;
  border-radius: ${({ theme }) => theme.shape.borderRadius.xl};

  ${applyButtonStyles};
`;
export const StyledA = styled.a `
  text-decoration: none;
`;
export const StyledLink = styled(Link) `
  text-decoration: none;
`;
