import { jsx as _jsx } from "react/jsx-runtime";
import { useDeviceContext } from '@src/contexts/DeviceContext';
import OneCell from './templates/OneCell';
import TwoCells from './templates/TwoCells';
import TwoCellsWitheLeg from './templates/TwoCellsWithLeg';
export default function Device({ open, hideTextStatus, isInvalidCode, isRenderFocusOnKeypad, isSmallDevice, }) {
    const { isTwoCells, isTwoCellsWithLeg } = useDeviceContext();
    if (isTwoCells) {
        return (_jsx(TwoCells, { open: open, hideTextStatus: hideTextStatus, isInvalidCode: isInvalidCode, isRenderFocusOnKeypad: isRenderFocusOnKeypad, isSmallDevice: isSmallDevice }));
    }
    if (isTwoCellsWithLeg) {
        return (_jsx(TwoCellsWitheLeg, { open: open, hideTextStatus: hideTextStatus, isInvalidCode: isInvalidCode, isRenderFocusOnKeypad: isRenderFocusOnKeypad, isSmallDevice: isSmallDevice }));
    }
    return (_jsx(OneCell, { open: open, hideTextStatus: hideTextStatus, isInvalidCode: isInvalidCode }));
}
