import { useEffect, useState } from 'react';
import { useApiContext } from '@src/contexts/ApiContext/ApiContext';
import { SignalLvl } from '../types';
export function useSignalLvl() {
    const [signalLvl, setSignal] = useState(SignalLvl.offline);
    const { deviceInfo } = useApiContext();
    function getSignalLvl(signalStrength) {
        if (signalStrength >= 2 && signalStrength <= 9) {
            setSignal(SignalLvl.marginal);
        }
        if (signalStrength >= 10 && signalStrength <= 14) {
            setSignal(SignalLvl.ok);
        }
        if (signalStrength >= 15 && signalStrength <= 19) {
            setSignal(SignalLvl.good);
        }
        if (signalStrength >= 20 && signalStrength) {
            setSignal(SignalLvl.excellent);
        }
    }
    useEffect(() => {
        const batteryInfo = deviceInfo?.battery_info;
        if (batteryInfo) {
            getSignalLvl(batteryInfo.signalStrength);
        }
    }, [deviceInfo]);
    return signalLvl;
}
