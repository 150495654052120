import { useEffect, useState } from 'react';
import { ErrorType } from '@src/api/types';
import { useApiContext } from '@src/contexts/ApiContext/ApiContext';
import { useDeviceContext } from '@src/contexts/DeviceContext/DeviceContext';
import { useNotifications } from '@src/contexts/NotificationsContext';
import { useFlowInfo } from '@src/hooks/useFlowInfo';
import { refreshCode } from '../utils/refreshCode';
export function useButtonActions() {
    const [isUpdating, setIsUpdating] = useState(false);
    const [sharedInfo, setSharedInfo] = useState('');
    const { description, phone, orders, name } = useFlowInfo();
    const { appData, updatePlacementCode } = useApiContext();
    const { selectedCell } = useDeviceContext();
    const { setNotification } = useNotifications();
    const orderIndex = selectedCell === undefined ? 0 : selectedCell;
    const order = orders[orderIndex];
    async function refreshCodeHandler() {
        if (!appData)
            return;
        setIsUpdating(true);
        refreshCode({
            userUid: appData.userUid,
            deviceUid: appData.deviceUid,
            placementCode: order?.code_placement || '',
        })
            .then((newCode) => {
            updatePlacementCode(orderIndex, newCode);
            setNotification({
                value: 'Access code updated successfully!',
                status: 'success',
            });
        })
            .catch((error) => {
            if (error.message === ErrorType.deviceOffline) {
                return setNotification({
                    value: 'Device Offline',
                    status: 'error',
                });
            }
            setNotification({
                value: 'Oops! Something went wrong. Please try again later.',
                status: 'error',
            });
        })
            .finally(() => setIsUpdating(false));
    }
    function shareText() {
        if (navigator?.share) {
            navigator
                .share({
                text: sharedInfo,
            })
                .then(() => setNotification({
                value: 'Info copied successfully',
                status: 'success',
            }))
                // eslint-disable-next-line no-console
                .catch((error) => console.error(error.message));
        }
        else {
            setNotification({
                value: '😢 Your browser does not support the web share api.',
                status: 'error',
            });
        }
    }
    useEffect(() => {
        const copyData = `
OMNIHOME: Smart Mailbox

${description};
1. Enter code: ${order?.code_placement};
2. Click OK;
Phone: ${phone};
Name: ${name};`;
        setSharedInfo(copyData);
    }, [description, order, phone, name, selectedCell]);
    return {
        isUpdating,
        shareText,
        refreshCodeHandler,
    };
}
