import styled, { css } from 'styled-components';
import { COLORS } from '@src/theme/colors';
function applyIndicatorFill({ percents, }) {
    let indicatorColor = '';
    if (percents >= 80)
        indicatorColor = COLORS.green['50'];
    if (percents < 80 && percents >= 60)
        indicatorColor = COLORS.yellow['30'];
    if (percents < 60 && percents >= 40)
        indicatorColor = COLORS.yellow['70'];
    if (percents < 40 && percents >= 20)
        indicatorColor = COLORS.orange;
    if (percents < 20)
        indicatorColor = COLORS.red['90'];
    return css `
    width: ${percents}%;
    background-color: ${indicatorColor};
  `;
}
export const BatteryBody = styled.div `
  position: relative;
  width: 24px;
  height: 12px;
  border: 1px solid #ffffff;
  border-radius: 2px;
  padding: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    background-color: #ffffff;
    width: 10%;
    height: 50%;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
    right: -3px;
  }
`;
export const BatteryIndicator = styled.div `
  align-self: flex-start;
  height: 100%;
  transition-duration: ${({ theme }) => theme.transitionDuration.normal};
  ${applyIndicatorFill};
`;
export const BatteryCore = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  overflow: hidden;
`;
