import styled from 'styled-components';
import { COLORS } from '@src/theme/colors';
function applySignalBgColor({ isActive }) {
    return isActive ? COLORS.grey[5] : COLORS.grey[80];
}
export const SignalContainer = styled.div `
  position: absolute;
  top: ${({ theme }) => theme.spacing()};
  left: ${({ theme }) => theme.spacing(2)};
  display: flex;
  align-items: flex-end;
  gap: ${({ theme }) => theme.spacing(0.25)};
`;
export const SignalPoint = styled.div `
  background-color: ${applySignalBgColor};
  border-radius: 2px;
  width: 4px;

  &:nth-child(1) {
    height: 6px;
  }

  &:nth-child(2) {
    height: 8px;
  }

  &:nth-child(3) {
    height: 10px;
  }

  &:nth-child(4) {
    height: 12px;
  }
`;
