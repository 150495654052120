import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useDeviceBehavior from '@src/components/Device/hooks/useDeviceBehavior';
import Battery from '../../components/Battery';
import Signal from '../../components/Signal';
import Status from '../../components/Status/Status';
import { CellPosition } from '../../types';
import { DeviceInfoWrapper, DeviceLoader, TextInfo } from '../../styled';
import { CellBottom, CellTop, DeviceBottom, DeviceCenterLeg, DeviceContainer, DeviceElementsWrapper, DeviceTop, DeviceWrapper, Door, Title, } from './styled';
export default function TwoCellsWitheLeg({ open, hideTextStatus, isInvalidCode, isRenderFocusOnKeypad, isSmallDevice, }) {
    const { isShowedTextStatus, isShowedDeviceInfo, isShowPercents, status, ownerFlow, selectedCell, toggleBatteryInfo, getStatusText, selectCellHandler, } = useDeviceBehavior({ hideTextStatus });
    return (_jsxs(DeviceWrapper, { "$isSmallDevice": isSmallDevice, children: [isShowedTextStatus && (_jsx(Status, { status: status, isInvalidCode: isInvalidCode })), _jsxs(DeviceElementsWrapper, { "$isRenderFocusOnKeypad": isRenderFocusOnKeypad, children: [isRenderFocusOnKeypad && (_jsx(Title, { children: "You can also use the keypad to place an order" })), _jsxs(DeviceContainer, { role: "presentation", "aria-label": "OmniHome device", "$isOpenedCell": open, "$isNeedSelect": !!ownerFlow, "$indexSelectedCell": selectedCell, onClick: toggleBatteryInfo, children: [_jsx(DeviceTop, {}), _jsx(CellTop, { onClick: selectCellHandler(CellPosition.top), children: _jsx(Door, {}) }), _jsxs(CellBottom, { status: status, onClick: selectCellHandler(CellPosition.bottom), children: [_jsx(Door, {}), isShowedDeviceInfo && (_jsxs(DeviceInfoWrapper, { children: [_jsx(Signal, {}), _jsx(Battery, { isShowPercents: isShowPercents }), _jsx(DeviceLoader, { children: _jsx(TextInfo, { children: getStatusText() }) })] }))] }), _jsx(DeviceBottom, { children: _jsx(DeviceCenterLeg, {}) })] })] })] }));
}
