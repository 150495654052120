import styled, { css, } from 'styled-components';
import { OwnerFlow } from '@src/contexts/OwnerFlowContext/type';
import { TopWrapper as BasicTopWrapper } from '@src/theme/commonElements';
import { ButtonCommon } from '@src/components/Button/styled';
export const TopWrapper = styled(BasicTopWrapper) ``;
function applyOpenCellModifiers({ $Flow, $isActive, }) {
    const opacity = $isActive ? '1' : '0.5';
    const preventClick = $isActive ? 'auto' : 'none';
    switch ($Flow) {
        case OwnerFlow.OpenCell:
            return css `
        width: 100%;
        color: #ffffff;
        background-color: #000000;
        opacity: ${opacity};
        pointer-events: ${preventClick};
      `;
        case OwnerFlow.CodeForCourier:
            return css `
        width: 0;
        background-color: #000;
        opacity: ${opacity};
        pointer-events: ${preventClick};
      `;
        default:
            return css ``;
    }
}
function applyCodeForCourierModifiers({ $Flow, $isActive, }) {
    const opacity = $isActive ? '1' : '0.5';
    const preventClick = $isActive ? 'auto' : 'none';
    switch ($Flow) {
        case OwnerFlow.CodeForCourier:
            return css `
        width: 100%;
        color: #ffffff;
        background-color: #000000;
        opacity: ${opacity};
        pointer-events: ${preventClick};
      `;
        case OwnerFlow.OpenCell:
            return css `
        width: 0;
        background-color: #000;
        opacity: ${opacity};
        pointer-events: ${preventClick};
      `;
        default:
            return css ``;
    }
}
export const ButtonsWrapper = styled.div `
  position: relative;
  max-width: 330px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.5)};
  flex-wrap: nowrap;

  ${ButtonCommon} {
    width: 50%;
    font-size: ${({ theme }) => theme.typography.fontSize.l};
    white-space: nowrap;
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    &:nth-child(even) {
      color: ${({ theme }) => theme.palette.text.primary};

      background-color: #fbff2f;
    }
  }

  ${ButtonCommon}:nth-child(1) {
    ${applyOpenCellModifiers}
  }

  ${ButtonCommon}:nth-child(2) {
    ${applyCodeForCourierModifiers}
  }
`;
