import { useCallback } from 'react';
import { useNavigate, useSearchParams, } from 'react-router-dom';
import { usePathPrefix } from './usePathPrefix';
export function useCustomNavigate() {
    const navigate = useNavigate();
    const prefix = usePathPrefix();
    const [searchParams] = useSearchParams();
    const navigateWithFlow = useCallback((to, options) => {
        navigate(`/${prefix}${to}?${searchParams.toString()}`, options);
    }, [navigate, prefix, searchParams]);
    const navigateToErrorPage = useCallback((errorType) => {
        navigate('/error', {
            state: errorType,
            replace: true,
        });
    }, [navigate]);
    return {
        navigateWithFlow,
        navigateToErrorPage,
    };
}
