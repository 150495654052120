import { request } from '@src/api';
export async function openDevice({ userUid, deviceUid, placementCode, }) {
    const data = await request({
        method: 'POST',
        url: '/omnihome/open/',
        data: {
            user_uid: userUid,
            device_uid: deviceUid,
            placement_code: placementCode,
        },
    });
    return data;
}
