import styled, { css } from 'styled-components';
import CloseSVG from '@src/assets/close.svg';
function applyNotificationStyle({ isActive, }) {
    return isActive
        ? css `
        transform: translateX(0px);
      `
        : css `
        transform: translateX(297px);
      `;
}
function applyBackground({ status }) {
    if (status === 'success')
        return 'rgba(246, 255, 239, 0.83)';
    if (status === 'error')
        return 'rgba(254, 243, 243, 0.83)';
    return 'rgba(255, 255, 255, 0.83)';
}
export const Notification = styled.div `
  position: fixed;
  right: ${({ theme }) => theme.spacing()};
  top: ${({ theme }) => theme.spacing(3)};
  width: 288px;
  min-height: 74px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 9999;

  background: ${applyBackground};
  border-radius: ${({ theme }) => theme.shape.borderRadius.m};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);

  transition-duration: ${({ theme }) => theme.transitionDuration.normal};
  ${applyNotificationStyle};
`;
export const Message = styled.p `
  flex-grow: 1;
  padding: ${({ theme }) => theme.spacing(3)};

  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeight[400]};
  font-size: ${({ theme }) => theme.typography.fontSize.s};
  color: ${({ theme }) => theme.palette.text.primary};
`;
export const CloseIcon = styled(CloseSVG) `
  min-width: 14px;
  min-height: 14px;
  align-self: flex-start;
  margin-right: ${({ theme }) => theme.spacing(3)};
  margin-top: ${({ theme }) => theme.spacing(4)};
`;
