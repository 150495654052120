import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDeviceContext } from '@src/contexts/DeviceContext';
import { useFlowInfo } from '@src/hooks/useFlowInfo';
import { MainTitle } from '@src/theme/commonElements';
import { useButtonActions } from './hooks/useButtonActions';
import { AccessCodeNumber, ButtonsWrapper, Number, RefreshButton, RefreshIcon, ShareButton, ShareIcon, } from './styled';
export default function AccessCode() {
    const { orders } = useFlowInfo();
    const { selectedCell } = useDeviceContext();
    const { isUpdating, shareText, refreshCodeHandler } = useButtonActions();
    const indexSelectedCell = selectedCell === undefined ? 0 : selectedCell;
    const order = orders[indexSelectedCell];
    return (_jsxs(_Fragment, { children: [_jsx(MainTitle, { children: "PIN code for the courier" }), _jsx(AccessCodeNumber, { children: order?.code_placement?.split('').map((number, index) => {
                    const key = `${number}/${index}`;
                    return _jsx(Number, { children: number }, key);
                }) }), _jsxs(ButtonsWrapper, { children: [_jsxs(ShareButton, { "aria-label": "Share info", onClick: shareText, children: [_jsx(ShareIcon, {}), "Share"] }), _jsxs(RefreshButton, { "aria-label": "Refresh code", isUpdating: isUpdating, onClick: refreshCodeHandler, children: [_jsx(RefreshIcon, {}), "Refresh"] })] })] }));
}
