import { useEffect, useState } from 'react';
import { DeviceStatus } from '@src/components/Device/types';
import { useApiContext } from '@src/contexts/ApiContext/ApiContext';
import { Flow } from '@src/contexts/ApiContext/types/context';
import { useDeviceContext } from '@src/contexts/DeviceContext';
import { useOwnerFlowContext } from '@src/contexts/OwnerFlowContext';
export default function useDeviceBehavior({ open, hideTextStatus, }) {
    const [status, setStatus] = useState(DeviceStatus.noData);
    const [isShowPercents, setIsShowPercents] = useState(false);
    const { ownerFlow } = useOwnerFlowContext();
    const { selectedCell, setSelectedCell } = useDeviceContext();
    const { appData, deviceInfo, deviceResStatus } = useApiContext();
    const { flow } = appData;
    const isShowedTextStatus = !hideTextStatus && flow === Flow.currier;
    const isCourierFlow = flow === Flow.currier;
    const isShowedDeviceInfo = !isCourierFlow && !open;
    function getStatusText() {
        const statusText = {
            [DeviceStatus.online]: 'Online',
            [DeviceStatus.offline]: 'Offline',
            [DeviceStatus.connecting]: 'Loading',
            [DeviceStatus.noData]: 'No data',
        };
        return statusText[status];
    }
    const selectCellHandler = (position) => (event) => {
        if (!ownerFlow || open)
            return;
        event.stopPropagation();
        setSelectedCell(position);
    };
    function toggleBatteryInfo() {
        setIsShowPercents((s) => !s);
    }
    useEffect(() => {
        if (deviceResStatus === 'pending')
            setStatus(DeviceStatus.connecting);
        if (deviceResStatus === 'rejected')
            setStatus(DeviceStatus.noData);
        if (deviceInfo) {
            const statusData = deviceInfo.is_online
                ? DeviceStatus.online
                : DeviceStatus.offline;
            setStatus(statusData);
        }
    }, [deviceInfo, deviceResStatus]);
    return {
        isShowedTextStatus,
        isShowedDeviceInfo,
        isShowPercents,
        status,
        ownerFlow,
        selectedCell,
        toggleBatteryInfo,
        getStatusText,
        selectCellHandler,
    };
}
