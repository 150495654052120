import { useCallback, useEffect, useState, } from 'react';
import { ErrorType } from '@src/api/types';
import { useApiContext } from '@src/contexts/ApiContext/ApiContext';
import { Flow } from '@src/contexts/ApiContext/types/context';
import { useDeviceContext } from '@src/contexts/DeviceContext';
import { useNotifications } from '@src/contexts/NotificationsContext';
import { openDevice } from '@src/utils/openDevice';
import { useCustomNavigate } from './useCustomNavigate';
export function useOpenDevice(value) {
    const [isLoading, setIsLoading] = useState(false);
    const [isInvalidCode, setIsInvalidCode] = useState(false);
    const { navigateWithFlow } = useCustomNavigate();
    const { setNotification } = useNotifications();
    const { setSelectedCell } = useDeviceContext();
    const { selectedCell } = useDeviceContext();
    const { appData } = useApiContext();
    const { deviceUid, userUid, data, flow } = appData;
    const { orders } = data;
    const errorHandler = useCallback((errorType) => {
        let offlineErrorMessage = 'Device Offline';
        let openCellErrorMessage = 'There is open cell. Please close cell';
        switch (errorType) {
            case ErrorType.deviceOffline:
                if (flow === Flow.currier) {
                    offlineErrorMessage =
                        'Device Offline, please wait or call to owner';
                }
                setNotification({
                    value: offlineErrorMessage,
                    status: 'error',
                });
                return;
            case ErrorType.invalidCode:
                setIsInvalidCode(true);
                setNotification({
                    value: 'Invalid placement code',
                    status: 'error',
                });
                return;
            case ErrorType.openCell:
                if (flow === Flow.currier) {
                    openCellErrorMessage =
                        'There is open cell. Please close cell or call to owner';
                }
                setNotification({
                    value: openCellErrorMessage,
                    status: 'error',
                });
                return;
            default:
                setNotification({
                    value: 'Oops! Something went wrong...',
                    status: 'error',
                });
        }
    }, [flow, setNotification]);
    const openDeviceHandler = useCallback(() => {
        const indexSelectedCell = selectedCell === undefined ? 0 : selectedCell;
        const accessCode = value
            ? value.join('')
            : orders[indexSelectedCell]?.code_placement;
        if (!accessCode)
            return;
        setIsLoading(true);
        openDevice({
            deviceUid,
            userUid,
            placementCode: accessCode || '',
        })
            // TODO: need to add open cell handler
            .then((response) => {
            const firstIndexCell = 0;
            const indexOpenCell = response?.extra?.cell || firstIndexCell;
            setSelectedCell(indexOpenCell);
            navigateWithFlow('/close-device');
        })
            .catch(({ message: errorType }) => errorHandler(errorType))
            .finally(() => setIsLoading(false));
    }, [
        value,
        orders,
        userUid,
        deviceUid,
        selectedCell,
        setSelectedCell,
        navigateWithFlow,
        errorHandler,
    ]);
    useEffect(() => {
        return () => setIsInvalidCode(false);
    }, []);
    return {
        isLoading,
        isInvalidCode,
        openDeviceHandler,
        setIsInvalidCode,
    };
}
