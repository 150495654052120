import styled from 'styled-components';
export const TextInfo = styled.p `
  font-family: ${({ theme }) => theme.typography.fontFamily.secondary};
  font-weight: ${({ theme }) => theme.typography.fontWeight[700]};
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-shadow: 0 0 1px #272727;
`;
export const DeviceInfoWrapper = styled.div `
  width: 100%;
  height: 100%;
  position: relative;

  z-index: 2;
`;
export const DeviceLoader = styled.div `
  width: 75px;
  height: 75px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 50%;
  border: 4px solid transparent;
`;
