import styled, { css } from 'styled-components';
import EyeSVG from '@src/assets/eye.svg';
import SunSVG from '@src/assets/sun.svg';
import WakeUpSVG from '@src/assets/wake-up.svg';
const icon = css `
  width: 24px;
  height: 24px;
`;
export const StatusWrapper = styled.div `
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.5)};
  min-height: 42px;
  font-size: ${({ theme }) => theme.typography.fontSize.l};
  font-weight: ${({ theme }) => theme.typography.fontWeight[400]};
  letter-spacing: ${({ theme }) => theme.spacing(0.04)};

  margin-top: ${({ theme }) => theme.spacing(1.5)};
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
`;
export const WakeUpIcon = styled(WakeUpSVG) `
  ${icon};
`;
export const SunIcon = styled(SunSVG) `
  ${icon};
`;
export const EyeIcon = styled(EyeSVG) `
  ${icon};
`;
