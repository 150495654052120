import styled, { css, keyframes, } from 'styled-components';
import { DeviceStatus, } from '../../types';
import { BatteryContainer } from '../../components/Battery/styled';
import { DeviceInfoWrapper, DeviceLoader, TextInfo } from '../../styled';
const generateKeypad = (color) => {
    const key = [];
    const keyWidth = 5;
    const gap = 3;
    for (let i = 0; i < 7; i++) {
        key.push(`${color} ${i * (keyWidth + gap)}px ${i * (keyWidth + gap) + keyWidth}px, transparent ${i * (keyWidth + gap) + keyWidth}px ${i * (keyWidth + gap) + (keyWidth + gap)}px`);
    }
    return key.join(', ');
};
const pulse = keyframes `
  0% {
    transform: scale(0);
    opacity: 1;
  }
  80% {
    border-color: #ffffff;
    box-shadow: 0 0 0 0 #ffffff;
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
    border-color: #ffffff;
    box-shadow: 0 0 0 2px #ffffff;
  }

`;
const deviceColor = css `
  background: linear-gradient(to right, #4c4c4c 0%, #c1c1c1 100%);
`;
const animatedCircle = css `
  content: '';
  position: absolute;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 4px solid ${({ theme }) => theme.palette.border.light};
  transform: scale(0);
  animation: infinite 4s ${pulse};
  box-sizing: border-box;
`;
const borderColor = '#161616';
function applyDeviceStyle({ status, }) {
    const isLoading = status !== DeviceStatus.online;
    return isLoading
        ? css `
        ${TextInfo} {
          opacity: 0;
        }

        ${DeviceLoader} {
          &::before {
            ${animatedCircle}
          }

          &::after {
            ${animatedCircle};
            border-color: ${({ theme }) => theme.palette.online};
            animation-delay: 2s;
          }
        }
      `
        : css `
        ${TextInfo} {
          color: ${({ theme }) => theme.palette.online};
        }

        ${DeviceLoader} {
          border-color: ${({ theme }) => theme.palette.online};

          &::before,
          &::after {
            display: none;
          }
        }
      `;
}
export const DeviceTop = styled.div `
  width: 100%;
  height: 43px;

  position: relative;

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 3px solid #717171;
  background-color: #151515;

  &::before {
    content: '';
    display: block;
    width: 60px;
    height: 20px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);

    border-radius: 2px;
    background-color: #c1c1c1;
    background-image: linear-gradient(to right, ${generateKeypad('#757971')}),
      linear-gradient(to right, ${generateKeypad('#757971')});

    background-size: 45px 5px;
    background-position: 7.5px 3px, 7.5px 12px;
    background-repeat: no-repeat;
  }

  &::after {
    content: '';
    display: block;
    width: 16px;
    height: 6px;

    position: absolute;
    top: 50%;
    left: 35%;
    transform: translateY(-50%);

    background-color: #6e736a;
  }
`;
export const Door = styled.div `
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  right: 0;

  transform: perspective(600px) rotateY(0deg);
  transform-origin: right;

  background: linear-gradient(to right, #c1c1c1 0%, #4c4c4c 100%);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0 solid #c1c1c1;
  box-shadow: inset 0 0 0 0 #000000, 0 0 0 0 #000000;

  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.5s 200ms;

  z-index: 1;
`;
export const CellTop = styled.div `
  position: relative;

  width: 100%;
  height: 100px;

  border: 1px solid ${borderColor};
  ${deviceColor};

  background:
    // START Small Package
    linear-gradient(-45deg, #bb7a02 42px 5px, transparent 43px 100%) 40% 34px /
      60px 6px no-repeat,
    linear-gradient(orange 146px 0, transparent 160px 100%) 40% 40px / 60px 20px
      no-repeat,
    // END Big Package
    // START Big Package
    linear-gradient(-45deg, #bb7a02 71px 6px, transparent 72px 100%) 40% 60% /
      100px 10px no-repeat,
    linear-gradient(orange 146px 0, transparent 160px 100%) 40% 92% / 100px 30px
      no-repeat,
    // END Big Package
    // START Screw
    radial-gradient(#3b3b3b 20%, transparent 25%) 0 0 / 15px 15px no-repeat,
    radial-gradient(#3b3b3b 20%, transparent 25%) 0 100% / 15px 15px no-repeat,
    // END Screw
    // START Lock
    linear-gradient(#000 0 10px) 3px 50% / 10px 5px no-repeat,
    // END Lock
    linear-gradient(to right, #c9e1ed 0 15px, transparent 15px 100%),
    linear-gradient(
        -230deg,
        #434343 0 calc(100% - 8px),
        transparent calc(100% - 8px) 100%
      )
      10px 0 / 20px 100% no-repeat,
    linear-gradient(-45deg, #30342c 146px 0, transparent 160px 100%) 0 100% /
      100% 12px no-repeat,
    linear-gradient(to right, #4c4c4c 0%, #858585 100%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${Door} {
    transform-origin: bottom right;

    &::before {
      content: 'OMNIC';
      display: flex;
      justify-content: center;
      align-items: center;

      width: 50%;
      height: 18px;

      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);

      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.2);

      font-weight: ${({ theme }) => theme.typography.fontWeight[700]};
      font-size: 8px;
      color: #ffffff;
      letter-spacing: 1px;

      transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.5s 200ms;

      z-index: 4;
    }
  }
`;
export const CellBottom = styled.div `
  position: relative;

  width: 100%;
  height: 180px;

  border: 1px solid ${borderColor};
  ${deviceColor};

  background:
    // START Small Package
    linear-gradient(-45deg, #bb7a02 42px 5px, transparent 43px 100%) 40% 50px /
      60px 10px no-repeat,
    linear-gradient(orange 146px 0, transparent 160px 100%) 40% 60px / 60px 30px
      no-repeat,
    // END Big Package
    // START Big Package
    linear-gradient(-45deg, #bb7a02 71px 6px, transparent 72px 100%) 40% 50% /
      100px 10px no-repeat,
    linear-gradient(orange 146px 0, transparent 160px 100%) 40% 100% / 100px
      85px no-repeat,
    // END Big Package
    // START Screw
    radial-gradient(#3b3b3b 20%, transparent 25%) 0 0 / 15px 15px no-repeat,
    radial-gradient(#3b3b3b 20%, transparent 25%) 0 100% / 15px 15px no-repeat,
    // END Screw
    // START Lock
    linear-gradient(#000 0 10px) 3px 50% / 10px 5px no-repeat,
    // END Lock
    linear-gradient(to right, #c9e1ed 0 15px, transparent 15px 100%),
    linear-gradient(
        -230deg,
        #434343 0 calc(100% - 8px),
        transparent calc(100% - 8px) 100%
      )
      10px 0 / 20px 100% no-repeat,
    linear-gradient(-45deg, #30342c 146px 0, transparent 160px 100%) 0 100% /
      100% 12px no-repeat,
    linear-gradient(to right, #4c4c4c 0%, #858585 100%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${applyDeviceStyle};

  ${Door} {
    transform-origin: top right;
  }

  ${BatteryContainer} {
    top: 8px;
    right: 16px;
    bottom: auto;
    left: auto;
  }
`;
export const DeviceBottom = styled.div `
  height: 13px;
  width: 100%;

  position: relative;

  border-top: 3px solid #717171;
  background: #151515;

  display: flex;
  justify-content: center;

  &:before {
    content: '';
    display: block;
    width: 225px;
    height: 80px;

    position: absolute;
    bottom: -80px;

    transform-origin: bottom center;
    transform: perspective(300px) rotateX(60deg);

    background: #0000003f;
    box-shadow: 0 0 10px 8px #0000003f;
    border-radius: 4px;
  }
`;
export const DeviceCenterLeg = styled.div `
  display: block;
  height: 65px;
  width: 40px;

  position: absolute;
  top: 0;

  background-color: inherit;

  &:before {
    content: '';
    display: block;
    width: 100px;
    height: 60px;

    position: absolute;
    left: -30px;
    bottom: -10px;

    transform-origin: bottom center;
    transform: perspective(300px) rotateX(60deg);

    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #060606;
    box-shadow: inset 0 0 8px #cacaca;

    z-index: 1;
  }

  &:after {
    content: '';
    display: block;
    width: 100px;
    height: 10px;

    position: absolute;
    left: -30px;
    bottom: -20px;

    background-color: black;
    box-shadow: inset 0 0 2px #cacaca;
  }
`;
function applySelectedCellModifiers({ $indexSelectedCell, $isOpenedCell, }) {
    if ($isOpenedCell) {
        return css ``;
    }
    switch ($indexSelectedCell) {
        case 0:
            return css `
        ${CellBottom}::after {
          content: 'THIS ONE';
          background-color: #68ec9e;
        }
      `;
        case 1:
            return css `
        ${CellTop}::after {
          content: 'THIS ONE';
          background-color: #68ec9e;
        }
      `;
        default:
            return css ``;
    }
}
function applyDeviceContainerModifiers({ $isNeedSelect, $isOpenedCell, }) {
    if ($isOpenedCell) {
        return css ``;
    }
    return $isNeedSelect
        ? css `
        ${DeviceInfoWrapper} {
          opacity: 0;
        }

        ${CellTop}::after, ${CellBottom}::after {
          content: '';
          display: flex;
          justify-content: center;
          align-items: center;
          color: #000000;
          font-weight: ${({ theme }) => theme.typography.fontWeight[700]};
          width: calc(100% - 18px);
          height: calc(100% - 18px);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          border-radius: 8px;
          border: 4px solid #68ec9e;

          z-index: 4;
        }
      `
        : css `
        ${CellTop}::after, ${CellBottom}::after {
          display: none;
        }
      `;
}
function applyOpenedCellModifiers({ $indexSelectedCell, $isOpenedCell, }) {
    const cell = ($indexSelectedCell === 0 && CellTop) ||
        ($indexSelectedCell === 1 && CellBottom);
    if (!cell) {
        return css ``;
    }
    return $isOpenedCell
        ? css `
        ${cell} {
          ${Door} {
            width: 65%;
            height: 105%;

            top: -5px;
            right: -1px;

            transform: perspective(600px) rotateY(40deg);

            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
            border-left: 5px solid #c1c1c1;
            box-shadow: inset 0 0 4px 0 #000000, 0 0 10px 0 #000000;

            z-index: 3;
          }
        }
      `
        : css ``;
}
export const DeviceContainer = styled.div `
  width: 235px;
  margin: ${({ theme }) => `${theme.spacing(3)} auto ${theme.spacing(13)}`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  transform: translateY(0) scale(1);
  transform-origin: center top;

  ${applyDeviceContainerModifiers};
  ${applySelectedCellModifiers};
  ${applyOpenedCellModifiers};
`;
function applyDeviceElementsWrapperModifiers({ $isRenderFocusOnKeypad, }) {
    return $isRenderFocusOnKeypad
        ? css `
        width: 100%;
        height: 280px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        border-radius: 9px;
        background: var(--bgf-8-f-8-f-3, #f8f8f3);

        padding-top: ${({ theme }) => theme.spacing(3.75)};
        margin-bottom: ${({ theme }) => theme.spacing(2)};

        overflow: hidden;

        ${DeviceContainer} {
          margin-top: 0;
        }

        ${DeviceTop}:before {
          box-shadow: 0 0 0 6px #151515, 0 0 0 9px #68ec9e;
        }
      `
        : css ``;
}
export const DeviceElementsWrapper = styled.div `
  ${applyDeviceElementsWrapperModifiers};
`;
export const Title = styled.h2 `
  font-size: ${({ theme }) => theme.typography.fontSize.e};
  font-weight: ${({ theme }) => theme.typography.fontWeight[700]};
  text-align: center;

  max-width: 240px;

  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;
function applyDeviceWrapperModifiers({ $isSmallDevice, }) {
    if ($isSmallDevice) {
        return css `
      height: 300px;

      & > div {
        position: relative;
        transform: scale(0.6);
        transform-origin: center top;
      }
    `;
    }
    return css ``;
}
export const DeviceWrapper = styled.div `
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  ${applyDeviceWrapperModifiers}
`;
