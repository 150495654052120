export const COLORS = {
    green: {
        40: '#5AFF9C',
        50: '#40fe11',
    },
    yellow: {
        30: '#bee414',
        70: '#e4e114',
    },
    orange: '#e49414',
    grey: {
        0: '#ffffff',
        5: '#E5E5E5',
        7: '#dfe9ed',
        10: '#e5e5e5',
        15: '#8B9AA1',
        20: '#CCCCCC',
        40: '#999999',
        70: '#4D4D4D',
        80: '#333333',
        100: '#000000',
    },
    red: {
        70: '#FF3E3E',
        80: '#E72F2F',
        90: '#D42222',
    },
};
