import { useEffect, useMemo, useState } from 'react';
import { TEXT_DELAY, TIME_TO_HIDE } from '../constants';
export function useNotificationSettings() {
    const [notification, setNotification] = useState({
        value: '',
        status: 'neutral',
    });
    const [isActive, setIsActive] = useState(false);
    function closeNotification() {
        setIsActive(false);
        setTimeout(() => setNotification({
            value: '',
            status: 'neutral',
        }), TEXT_DELAY);
    }
    useEffect(() => {
        if (notification.value) {
            setIsActive(true);
        }
    }, [notification.value]);
    useEffect(() => {
        if (!isActive)
            return;
        const timeToHide = setTimeout(closeNotification, TIME_TO_HIDE);
        return () => {
            clearTimeout(timeToHide);
        };
    }, [isActive]);
    const value = useMemo(() => ({
        setNotification,
    }), []);
    return {
        value,
        notification,
        isActive,
        closeNotification,
    };
}
