import styled, { css, } from 'styled-components';
function applyFramesStyle({ isError, }) {
    return isError
        ? css `
        input {
          color: ${({ theme }) => theme.palette.error[90]};
          border-color: ${({ theme }) => theme.palette.error[90]};
        }
      `
        : css `
        input {
          box-shadow: none;
        }
      `;
}
export const Form = styled.form `
  position: relative;
  margin-top: ${({ theme }) => theme.spacing(1.5)};
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const FramesWrapper = styled.div `
  height: 100%;
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(2)};

  ${applyFramesStyle};
`;
export const SingleInput = styled.input `
  background: transparent;
  outline: none;
  text-align: center;

  display: flex;
  align-items: center;
  width: 56px;
  height: 80px;
  font-size: 32px;
  font-family: ${({ theme }) => theme.typography.fontFamily.special};
  font-weight: ${({ theme }) => theme.typography.fontWeight[400]};

  border: 1px solid ${({ theme }) => theme.palette.border.black};
  border-radius: ${({ theme }) => theme.shape.borderRadius.xs};
  background-color: transparent;

  &::placeholder {
    font-size: 16px;
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};
    color: ${({ theme }) => theme.palette.text.special};
    font-weight: ${({ theme }) => theme.typography.fontWeight[700]};
  }
`;
