import { ErrorType } from '../types';
export function identifyError(reason) {
    if (reason.includes('open_cell')) {
        throw new Error(ErrorType.openCell);
    }
    if (reason.includes('device_offline')) {
        throw new Error(ErrorType.deviceOffline);
    }
    if (reason.includes('invalid_placement_code')) {
        throw new Error(ErrorType.invalidCode);
    }
}
