import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useSearchParams } from 'react-router-dom';
import Button from '@src/components/Button';
import Device from '@src/components/Device';
import { useApiContext } from '@src/contexts/ApiContext/ApiContext';
import { Flow } from '@src/contexts/ApiContext/types/context';
import { MainTitle, TopWrapper } from '@src/theme/commonElements';
import { SubTitle } from './styled';
export default function Done() {
    const { appData } = useApiContext();
    const [searchParams] = useSearchParams();
    const { flow } = appData;
    return (_jsxs(_Fragment, { children: [_jsxs(TopWrapper, { children: [_jsx(MainTitle, { children: "Done!" }), flow === Flow.currier && (_jsx(SubTitle, { children: "The customer will receive a text message about the order" }))] }), _jsx(Device, { hideTextStatus: true }), flow === Flow.owner && (_jsx(Button, { to: `/?${searchParams.toString()}`, children: "Main page" }))] }));
}
