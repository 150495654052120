import styled, { css, keyframes, } from 'styled-components';
import { DeviceStatus } from '../../types';
export const indicate = keyframes `
  0% {
    border-color: #ffffff;
    box-shadow: 0 0 1px 0 #00d200, inset 0 0 1px 0 #00d200;
  }
  50% {
    border-color: #00d200;
    box-shadow: 0 0 5px 1px #00d200, inset 0 0 5px 1px #00d200;
  }
  100% {
    border-color: #ffffff;
    box-shadow: 0 0 1px 0 #00d200, inset 0 0 1px 0 #00d200;
  }
`;
const pulse = keyframes `
  0% {
    transform: scale(0);
    opacity: 1;
  }
  80% {
    border-color: #ffffff;
    box-shadow: 0 0 0 0 #ffffff;
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
    border-color: #ffffff;
    box-shadow: 0 0 0 2px #ffffff;
  }
 
`;
const deviceColor = css `
  background: linear-gradient(to right, #4c4c4c 0%, #c1c1c1 100%);
`;
const animatedCircle = css `
  content: '';
  position: absolute;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 4px solid ${({ theme }) => theme.palette.border.light};
  transform: scale(0);
  animation: infinite 4s ${pulse};
  box-sizing: border-box;
`;
const borderColor = '#161616';
const leg = css `
  position: absolute;
  height: 50px;
  width: 12px;
  top: 0;
  background-color: inherit;

  &:before {
    content: '';
    position: absolute;
    width: 8px;
    z-index: -1;
    top: 9px;
    height: 60%;
    background-color: black;
  }
`;
export const DeviceContainer = styled.div `
  margin-top: ${({ theme }) => theme.spacing(3)};
  margin-bottom: ${({ theme }) => theme.spacing(8)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const ClosedCover = styled.div `
  height: 10px;
  width: 290px;
  border: 1px solid ${borderColor};
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  ${deviceColor};
`;
export const OpenedCover = styled.div `
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 255px;
  height: 160px;
  border: 1px solid #2f2b27;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-top-width: 12px;
  border-left-width: 4px;
  border-right-width: 4px;
  ${deviceColor};

  box-shadow: 5px -20px 40px -2px inset #201d1b;

  &::before {
    content: '';
    position: absolute;
    width: 3px;
    height: 6px;
    border-radius: 3px;
    background-color: #909090;
    top: -9px;
  }

  &::after {
    content: '';
    width: 100%;
    height: 30px;
    background-color: #2c2c2b83;
    border: 1px inset grey;
  }
`;
export const CoverFastening = styled.div `
  position: absolute;
  width: 10px;
  height: 30px;
  background-color: #151515;
  bottom: 0;
  display: flex;
  justify-content: center;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 4px;
    height: 20px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;

    box-shadow: inset -3px 0 5px -5px #ffffff;
  }

  &:first-child {
    left: 0;
    border-top-right-radius: 4px;
  }

  &:last-child {
    right: 0;
    border-top-left-radius: 4px;
  }
`;
export const DeviceLoader = styled.div `
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 4px solid transparent;
`;
export const TextInfo = styled.p `
  font-family: ${({ theme }) => theme.typography.fontFamily.secondary};
  font-weight: ${({ theme }) => theme.typography.fontWeight[700]};
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-shadow: 0 0 1px #272727;
`;
function applyDeviceStyle({ status, }) {
    const isLoading = status !== DeviceStatus.online;
    return isLoading
        ? css `
        ${TextInfo} {
          opacity: 0;
        }

        ${DeviceLoader} {
          &::before {
            ${animatedCircle}
          }

          &::after {
            ${animatedCircle};
            border-color: ${({ theme }) => theme.palette.online};
            animation-delay: 2s;
          }
        }
      `
        : css `
        ${TextInfo} {
          color: ${({ theme }) => theme.palette.online};
        }

        ${DeviceLoader} {
          border-color: ${({ theme }) => theme.palette.online};

          &::before,
          &::after {
            display: none;
          }
        }
      `;
}
export const DeviceBody = styled.div `
  position: relative;
  height: 180px;
  width: 290px;
  border: 1px solid ${borderColor};
  ${deviceColor};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${applyDeviceStyle};
`;
export const DeviceBottom = styled.div `
  height: 10px;
  width: 260px;
  background: #151515;
  position: relative;
  display: flex;
  justify-content: center;

  &:before {
    content: '';
    position: absolute;
    width: 255px;
    height: 20px;
    background: #0000003f;
    box-shadow: 0 0 10px 8px #0000003f;
    rotate: x 40deg;
    bottom: -40px;
  }
`;
export const DeviceLeftLeg = styled.div `
  ${leg};
  left: -2px;
  rotate: 2 -4 1 10deg;

  &:before {
    left: 12px;
  }
`;
export const DeviceRightLeg = styled.div `
  ${leg};
  right: -2px;
  rotate: 2 4 1 -10deg;

  &:before {
    right: 12px;
  }
`;
