import { jsx as _jsx } from "react/jsx-runtime";
import { MAX_SIGNAL_LVL } from './constants';
import { useSignalLvl } from './hooks/useSignalLvl';
import { SignalContainer, SignalPoint } from './styled';
export default function Signal() {
    const signalLvl = useSignalLvl();
    function getSignalPoints() {
        const points = [];
        for (let i = 1; i <= MAX_SIGNAL_LVL; i++) {
            const isActive = i <= signalLvl;
            points.push(_jsx(SignalPoint, { isActive: isActive }, i));
        }
        return points;
    }
    return _jsx(SignalContainer, { children: getSignalPoints() });
}
