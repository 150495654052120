export var Flow;
(function (Flow) {
    Flow["none"] = "none";
    Flow["owner"] = "owner";
    Flow["currier"] = "currier";
    Flow["error"] = "error";
})(Flow || (Flow = {}));
export var SearchParams;
(function (SearchParams) {
    SearchParams["owner"] = "owner";
    SearchParams["deviceUid"] = "device_uid";
})(SearchParams || (SearchParams = {}));
