import styled, { keyframes } from 'styled-components';
const load = keyframes `
  0% {
    transform: rotate(0deg);
  };
  100% {
    transform: rotate(360deg);
  };
`;
export const Loading = styled.div `
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation: ${load} 1.1s infinite linear;

  border-top: 5px solid rgba(14, 255, 14, 0.521);
  border-right: 5px solid rgba(14, 255, 14, 0.521);
  border-bottom: 5px solid rgba(14, 255, 14, 0.521);
  border-left: 5px solid ${({ theme }) => theme.palette.online};

  &,
  &::after {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
`;
