import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Outlet, Route, Routes } from 'react-router-dom';
import ErrorPage from '@src/pages/ErrorPage';
import Header from '../Header';
import LoaderBig from '../LoaderBig';
import { useDataChecking } from './hooks/useDataChecking';
import { CommonWrapper, MainContainer } from './styles';
export default function ComponentsWrapper() {
    const { isLoading, isDataAvailable } = useDataChecking();
    return (_jsxs(CommonWrapper, { children: [_jsx(Header, {}), _jsxs(MainContainer, { children: [isLoading && _jsx(LoaderBig, {}), isDataAvailable && _jsx(Outlet, {}), _jsx(Routes, { children: _jsx(Route, { path: "/error", element: _jsx(ErrorPage, {}) }) })] })] }));
}
