import { useApiContext } from '@src/contexts/ApiContext/ApiContext';
export function useFlowInfo() {
    const { appData } = useApiContext();
    const { data: { company_franchisee: { name, phone, description }, orders, }, } = appData;
    return {
        name,
        phone,
        description,
        orders,
    };
}
