import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import Button from '@src/components/Button';
import Device from '@src/components/Device';
import Loader from '@src/components/Loader';
import { useOpenDevice } from '@src/hooks/useOpenDevice';
import { TopWrapper } from '@src/theme/commonElements';
import AccessCode from './components/AccessCode';
import { CODE_LENGTH } from './components/AccessCode/constants';
import { DELAY_TO_SUBMIT } from './constants';
export default function CurrierPage() {
    const [value, setValue] = useState(['']);
    const isValidCode = value.length === CODE_LENGTH && !value.some((number) => !number);
    const { isInvalidCode, isLoading, setIsInvalidCode, openDeviceHandler } = useOpenDevice(value);
    useEffect(() => {
        if (isValidCode) {
            const timeToOpenDevice = setTimeout(() => {
                openDeviceHandler();
            }, DELAY_TO_SUBMIT);
            return () => {
                clearTimeout(timeToOpenDevice);
            };
        }
    }, [value, isValidCode, openDeviceHandler]);
    return (_jsxs(_Fragment, { children: [_jsx(TopWrapper, { children: _jsx(AccessCode, { isError: isInvalidCode, setIsInvalidCode: setIsInvalidCode, value: value, setValue: setValue }) }), _jsx(Device, { isInvalidCode: isInvalidCode, isRenderFocusOnKeypad: true }), _jsx(Button, { isActive: !isLoading, onClick: openDeviceHandler, children: isLoading ? _jsx(Loader, {}) : 'Open Device' })] }));
}
