import { useDeviceContext } from '@src/contexts/DeviceContext';
import { useOwnerFlowContext } from '@src/contexts/OwnerFlowContext';
import { OwnerFlow } from '@src/contexts/OwnerFlowContext/type';
import { useOpenDevice } from '@src/hooks/useOpenDevice';
export default function useButtonForTwoCells() {
    const { ownerFlow, isShowAccessCode, setOwnerFlow, setIsShowAccessCode } = useOwnerFlowContext();
    const { setSelectedCell } = useDeviceContext();
    const { openDeviceHandler } = useOpenDevice();
    const isSelectedOpenCell = ownerFlow === OwnerFlow.OpenCell;
    const isSelectedCodeForCourier = ownerFlow === OwnerFlow.CodeForCourier;
    const openCellBtn = {
        text: 'Open Cell',
        action: () => {
            if (isSelectedOpenCell) {
                openDeviceHandler();
            }
            else {
                setOwnerFlow(OwnerFlow.OpenCell);
            }
        },
    };
    const codeForCourierBtn = isShowAccessCode
        ? {
            text: 'Main',
            action: () => {
                setIsShowAccessCode(false);
                setSelectedCell(undefined);
                setOwnerFlow(undefined);
            },
        }
        : {
            text: isSelectedCodeForCourier ? 'Next' : 'Code for Courier',
            action: () => {
                if (isSelectedCodeForCourier) {
                    setIsShowAccessCode(true);
                }
                else {
                    setOwnerFlow(OwnerFlow.CodeForCourier);
                }
            },
        };
    return { isSelectedCodeForCourier, openCellBtn, codeForCourierBtn };
}
