import styled, { css, keyframes, } from 'styled-components';
import DeviceTwoCells from '@src/assets/images/device_two_cells.png';
import OpenedBottomCell from '@src/assets/images/device_two_cells_bottom.png';
import OpenedTopCell from '@src/assets/images/device_two_cells_top.png';
import { DeviceStatus, } from '../../types';
import { BatteryContainer } from '../../components/Battery/styled';
import { DeviceInfoWrapper, DeviceLoader, TextInfo } from '../../styled';
const pulse = keyframes `
  0% {
    transform: scale(0);
    opacity: 1;
  }
  80% {
    border-color: #ffffff;
    box-shadow: 0 0 0 0 #ffffff;
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
    border-color: #ffffff;
    box-shadow: 0 0 0 2px #ffffff;
  }
 
`;
const animatedCircle = css `
  content: '';
  position: absolute;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 4px solid ${({ theme }) => theme.palette.border.light};
  transform: scale(0);
  animation: infinite 4s ${pulse};
  box-sizing: border-box;
`;
function applyDeviceStyle({ status, }) {
    const isLoading = status !== DeviceStatus.online;
    return isLoading
        ? css `
        ${TextInfo} {
          opacity: 0;
        }

        ${DeviceLoader} {
          &::before {
            ${animatedCircle}
          }

          &::after {
            ${animatedCircle};
            border-color: ${({ theme }) => theme.palette.online};
            animation-delay: 2s;
          }
        }
      `
        : css `
        ${TextInfo} {
          color: ${({ theme }) => theme.palette.online};
        }

        ${DeviceLoader} {
          border-color: ${({ theme }) => theme.palette.online};

          &::before,
          &::after {
            display: none;
          }
        }
      `;
}
export const DeviceTop = styled.div `
  width: 100%;
  height: 43px;

  position: relative;

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;
export const CellTop = styled.div `
  position: absolute;
  top: 15%;
  left: 49%;
  width: 75%;
  height: 20%;
  transform: translateX(-50%);
`;
export const CellBottom = styled.div `
  position: absolute;
  bottom: 20%;
  left: 49%;
  width: 75%;
  height: 42%;
  transform: translateX(-50%);

  ${applyDeviceStyle};

  ${BatteryContainer} {
    top: 8px;
    right: 16px;
    bottom: auto;
    left: auto;
  }
`;
function applySelectedCellModifiers({ $indexSelectedCell, $isOpenedCell, }) {
    const openedCellStyle = css `
    ${CellTop}, ${CellBottom} {
      display: none;
    }
  `;
    switch ($indexSelectedCell) {
        case 0:
            return $isOpenedCell
                ? css `
            ${openedCellStyle};
            background-image: url(${OpenedTopCell});
          `
                : css `
            ${CellBottom}::after {
              content: 'THIS ONE';
              background-color: #68ec9e;
            }
          `;
        case 1:
            return $isOpenedCell
                ? css `
            ${openedCellStyle};
            background-image: url(${OpenedBottomCell});
          `
                : css `
            ${CellTop}::after {
              content: 'THIS ONE';
              background-color: #68ec9e;
            }
          `;
        default:
            return css ``;
    }
}
function applyDeviceContainerModifiers({ $isNeedSelect, $isOpenedCell, }) {
    if ($isOpenedCell) {
        return css ``;
    }
    return $isNeedSelect
        ? css `
        ${DeviceInfoWrapper} {
          opacity: 0;
        }

        ${CellTop}::after, ${CellBottom}::after {
          content: '';
          display: flex;
          justify-content: center;
          align-items: center;
          color: #000000;
          font-weight: ${({ theme }) => theme.typography.fontWeight[700]};
          width: calc(100% - 18px);
          height: calc(100% - 18px);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          border-radius: 8px;
          border: 4px solid #68ec9e;

          z-index: 4;
        }
      `
        : css `
        ${CellTop}::after, ${CellBottom}::after {
          display: none;
        }
      `;
}
export const DeviceContainer = styled.div `
  position: relative;
  width: 100%;

  background-image: url(${DeviceTwoCells});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;

  padding-top: 145%;
  margin: 0 auto;

  ${applyDeviceContainerModifiers};
  ${applySelectedCellModifiers};
`;
function applyDeviceElementsWrapperModifiers({ $isRenderFocusOnKeypad, }) {
    return $isRenderFocusOnKeypad
        ? css `
        width: 100%;
        height: 280px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        border-radius: 9px;
        background: var(--bgf-8-f-8-f-3, #f8f8f3);

        padding-top: ${({ theme }) => theme.spacing(3.75)};
        margin-bottom: ${({ theme }) => theme.spacing(2)};

        overflow: hidden;

        ${DeviceContainer} {
          margin-top: 0;
        }

        ${DeviceTop}:before {
          box-shadow: 0 0 0 6px #151515, 0 0 0 9px #68ec9e;
        }
      `
        : css ``;
}
export const DeviceElementsWrapper = styled.div `
  max-width: 400px;
  width: 90%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  ${applyDeviceElementsWrapperModifiers};
`;
export const Title = styled.h2 `
  font-size: ${({ theme }) => theme.typography.fontSize.e};
  font-weight: ${({ theme }) => theme.typography.fontWeight[700]};
  text-align: center;

  max-width: 240px;

  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;
function applyDeviceWrapperModifiers({ $isSmallDevice, }) {
    if ($isSmallDevice) {
        return css `
      width: 70%;
    `;
    }
    return css ``;
}
export const DeviceWrapper = styled.div `
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  ${applyDeviceWrapperModifiers}
`;
