import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useApiContext } from '@src/contexts/ApiContext/ApiContext';
import BatteryUnit from './components/BatteryUnit';
import { BatteryBox, BatteryContainer, BatteryNumber } from './styled';
export default function Battery({ isShowPercents }) {
    const [battery1ChargeLevel, setBattery1ChargeLevel] = useState();
    const [battery2ChargeLevel, setBattery2ChargeLevel] = useState();
    const { deviceInfo } = useApiContext();
    const isBattery1Defined = Number.isInteger(battery1ChargeLevel);
    const isBattery2Defined = Number.isInteger(battery2ChargeLevel);
    const bat1Info = isShowPercents
        ? `${battery1ChargeLevel}%`
        : `Bat ${isBattery2Defined ? '1' : ''}`;
    const bat2Info = isShowPercents
        ? `${battery2ChargeLevel}%`
        : `Bat ${isBattery1Defined ? '2' : ''}`;
    useEffect(() => {
        const batteryInfo = deviceInfo?.battery_info;
        if (batteryInfo) {
            if (batteryInfo.solar_panel_battery) {
                if (batteryInfo.batteryActive === 1) {
                    return setBattery1ChargeLevel(batteryInfo.battery1_charge_level);
                }
                return setBattery2ChargeLevel(batteryInfo.battery2_charge_level);
            }
            setBattery1ChargeLevel(batteryInfo.battery1_charge_level);
            setBattery2ChargeLevel(batteryInfo.battery2_charge_level);
        }
    }, [deviceInfo]);
    return (_jsxs(BatteryContainer, { children: [_jsx(BatteryBox, { children: isBattery1Defined && (_jsxs(_Fragment, { children: [_jsx(BatteryUnit, { percents: battery1ChargeLevel }), _jsx(BatteryNumber, { children: bat1Info })] })) }), _jsx(BatteryBox, { children: isBattery2Defined && (_jsxs(_Fragment, { children: [_jsx(BatteryUnit, { percents: battery2ChargeLevel }), _jsx(BatteryNumber, { children: bat2Info })] })) })] }));
}
