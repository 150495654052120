import styled from 'styled-components';
export const Image = styled.img `
  display: block;
  border-radius: 15px;
  max-width: 100%;
`;
export const ErrorInfoContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;
