export var CellPosition;
(function (CellPosition) {
    CellPosition[CellPosition["bottom"] = 0] = "bottom";
    CellPosition[CellPosition["top"] = 1] = "top";
})(CellPosition || (CellPosition = {}));
export var DeviceStatus;
(function (DeviceStatus) {
    DeviceStatus[DeviceStatus["online"] = 0] = "online";
    DeviceStatus[DeviceStatus["offline"] = 1] = "offline";
    DeviceStatus[DeviceStatus["connecting"] = 2] = "connecting";
    DeviceStatus[DeviceStatus["noData"] = 3] = "noData";
})(DeviceStatus || (DeviceStatus = {}));
