import styled from 'styled-components';
export const BatteryContainer = styled.div `
  position: absolute;
  left: ${({ theme }) => theme.spacing(2)};
  bottom: ${({ theme }) => theme.spacing()};
  display: flex;
  gap: ${({ theme }) => theme.spacing()};
`;
export const BatteryBox = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
`;
export const BatteryNumber = styled.p `
  color: ${({ theme }) => theme.palette.text.hightLight};
  font-family: ${({ theme }) => theme.typography.fontFamily.secondary};
  font-weight: ${({ theme }) => theme.typography.fontWeight[700]};
  font-size: 7px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-right: -2px;
`;
