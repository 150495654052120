import styled, { keyframes } from 'styled-components';
import { COLORS } from '@src/theme/colors';
const color = COLORS.green[50];
const pulsIn = keyframes `
  0% {
    box-shadow: inset 0 0 0 1rem ${color};
    opacity: 1;
  }
  50%, 100% {
    box-shadow: inset 0 0 0 0 ${color};
    opacity: 0;
  }
`;
const pulsOut = keyframes `
  0%, 50% {
    box-shadow: 0 0 0 0 ${color};
    opacity: 0;
  }
  100% {
    box-shadow: 0 0 0 1rem ${color};
    opacity: 1;
  }
`;
export const LoaderContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  height: 100%;
`;
export const Loader = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &::before,
  &::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    animation: ${pulsOut} 1.8s ease-in-out infinite;
    filter: drop-shadow(0 0 1rem ${color});
  }

  &::before {
    width: 70%;
    padding-bottom: 70%;
    box-shadow: inset 0 0 0 1rem ${color};
    animation-name: ${pulsIn};
  }

  &::after {
    width: calc(70% - 2rem);
    padding-bottom: calc(70% - 2rem);
  }
`;
