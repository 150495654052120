import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
import { useNotificationSettings } from './hooks/useNotificationSettings';
import { CloseIcon, Message, Notification } from './styled';
const Context = createContext(null);
export default function NotificationsContext({ children, }) {
    const { value, isActive, notification, closeNotification } = useNotificationSettings();
    return (_jsxs(Context.Provider, { value: value, children: [_jsxs(Notification, { status: notification.status, isActive: isActive, "aria-label": "Notification window", children: [_jsx(Message, { dangerouslySetInnerHTML: { __html: notification.value } }), _jsx(CloseIcon, { role: "button", "aria-label": "Close notification window", onClick: closeNotification })] }), children] }));
}
export function useNotifications() {
    const contextData = useContext(Context);
    if (!contextData)
        throw new Error('Please use hook useNotifications inside NotificationsContext provider');
    return contextData;
}
