import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
const Context = createContext(null);
export default function OwnerFlowContext({ children, }) {
    const [ownerFlow, setOwnerFlow] = useState();
    const [isShowAccessCode, setIsShowAccessCode] = useState(false);
    const [isShowClearBtn, setIsShowClearBtn] = useState(false);
    const value = useMemo(() => ({
        ownerFlow,
        isShowAccessCode,
        isShowClearBtn,
        setOwnerFlow,
        setIsShowAccessCode,
        setIsShowClearBtn,
    }), [ownerFlow, isShowAccessCode, isShowClearBtn]);
    useEffect(() => {
        if (ownerFlow) {
            setIsShowClearBtn(true);
        }
    }, [ownerFlow]);
    return _jsx(Context.Provider, { value: value, children: children });
}
export function useOwnerFlowContext() {
    const contextData = useContext(Context);
    if (!contextData)
        throw new Error('Please use hook useOwnerFlow inside OwnerFlowContext provider');
    return contextData;
}
