import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { MainTitle } from '@src/theme/commonElements';
import { CODE_LENGTH } from './constants';
import { useInputActions } from './hooks/useInputActions';
import { Form, FramesWrapper, SingleInput } from './styled';
export default function AccessCode({ value, isError, setValue, setIsInvalidCode, }) {
    const placeholder = '_';
    const { onChangeHandler, onFocusHandler, onkeydownHandler } = useInputActions({
        setValue,
        setIsInvalidCode,
    });
    function getInputs() {
        const inputs = [];
        for (let i = 0; i < CODE_LENGTH; i++) {
            inputs.push(_jsx(SingleInput, { type: "text", inputMode: "numeric", pattern: "[0-9]*", value: value[i] || '', placeholder: placeholder, id: String(i), onFocus: (event) => {
                    event.target.placeholder = '';
                    onFocusHandler(event);
                }, onBlur: (event) => {
                    event.target.placeholder = placeholder;
                }, onInput: onChangeHandler, onKeyDown: onkeydownHandler }, `input/${i}`));
        }
        return inputs;
    }
    return (_jsxs(_Fragment, { children: [_jsx(MainTitle, { children: "Enter the access code" }), _jsx(Form, { children: _jsx(FramesWrapper, { isError: isError, children: getInputs() }) })] }));
}
