import { request } from '@src/api';
export async function refreshCode({ userUid, deviceUid, placementCode, }) {
    const { data } = await request({
        method: 'POST',
        url: '/omnihome/new/code/',
        data: {
            user_uid: userUid,
            device_uid: deviceUid,
            placement_code: placementCode,
        },
    });
    return data.placement_code;
}
