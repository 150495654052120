import { useApiContext } from '@src/contexts/ApiContext/ApiContext';
export function useDataChecking() {
    const { dataResStatus } = useApiContext();
    const isLoading = dataResStatus === 'pending';
    const isDataAvailable = dataResStatus === 'received';
    return {
        isLoading,
        isDataAvailable,
    };
}
